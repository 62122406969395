import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { ReactSVG } from "react-svg";
import style from "../AssignObjectives/AssignObjectives.module.scss";
import * as actions from "store/actions/index";
import {
  ACCEPT_OR_REJECT_COMPLETION,
  APPROVE_SELF_OBJECTIVE,
  CANCEL_OBJECTIVE,
  REJECT_SELF_OBJECTIVE,
} from "util/endpoints";
import { IC_NOTIFY } from "const/imgCost";
import { scrollToTop, requiredFieldMissing, successToast, errorToast } from "util/general";
import { useQuery } from "util/utils";
import { noData, objectiveStatusValue, status } from "const/constValue";
import http from "util/http";
import storage from "util/storage";
import ObjectiveActivities from "./ObjectiveActivities";
import SlideToast from "../../Toast/Toast";
import ObjectiveHeading from "./ObjectiveHeading";
import ObjectiveKeyResults from "../AssignObjectives/ObjectiveKeyResults";
import ObjectiveOwner from "../AssignObjectives/ObjectiveOwner";
import ObjectiveAssignee from "../AssignObjectives/ObjectiveAssignee";
import ConfirmationPopup from "../ConfirmationPopup";
import KeyAccordion from "../AllObjectives/UnivertsityCreatedObjective/Key_Result_Accordion";
import SideBarTags from "../../Sidebar-tags/SidebarTags";
import ObjectiveDescription from "./ObjectiveDescription";
import RatingKeyResults from "./RatingKeyResults";
import RatingKeyResultsUni from "./RatingKeyResultsUni";
import RejectMessageModal from "../RejectMessageModal/RejectMessageModal";
import AcceptAndReject from "../../AcceptAndReject/AcceptAndReject";
import CommentSubmit from "../../CommentSubmit/CommentSubmit";
import NoDataFound from "../../NodataFound/NoDataFound";
import Spinner from "shared/Spinner/Spinner";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import routePath from "const/routePath";

export default function AssignObjective(props) {
  const employeeId = useQuery().get("empId");
  const parentGroupId = useQuery().get("pgId");
  const subGroupId = useQuery().get("sgId");
  const dispatch = useDispatch();
  let loggedInUser = storage.getUser();
  const history = useHistory();
  const loggedInUserEmpId = loggedInUser?.employeeId;
  const userObjectiveId = props.match.params.id;
  const employeeIds = {
    employeeId,
    parentGroupId,
    subGroupId,
  };
  const [rejectModel, setRejectModel] = useState(false);
  const [display, setDisplay] = useState(false);
  const [rejectSelfAssign, setRejectSelfAssign] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [activityData, setActivityData] = useState({});
  const [displayCompletion, setDisplayCompletion] = useState(true);
  const [objectiveId, setObjectiveId] = useState(null);
  const [acceptRejectFlag, setAcceptRejectFlag] = useState();
  const { objective, isLoading, resourceNotFound, activities, deleteUserObjectiveResponse, selfObjectiveData } = useSelector(
    (state) => state.objectives
  );
  const headerText = "Sure you want to delete this objective?";
  const subText = "Since the objective has weightage attached to it, we advise altering it. You cannot undo a deletion once it has been made.";

  let isSubmitFlag = "-";

  useEffect(() => {
    if (deleteUserObjectiveResponse) {
      if (deleteUserObjectiveResponse.success) {
        successToast(deleteUserObjectiveResponse.message);
        history.push(routePath.TEAMS_OBJECTIVE);
      }
      dispatch({ type: "CLEAR_FILE_OBJECTIVE_RESPONSE" });
    }
  }, [deleteUserObjectiveResponse, dispatch, history]);

  useEffect(() => {
    dispatch({ type: "CLEAR_OBJECTIVES_STATE" });
    scrollToTop(500);
    setDisplayCompletion(true);
    //GetObjective Activity is user manager objective activity
    if (employeeIds) {
      dispatch(actions.GetAssignedUserObjective(userObjectiveId, employeeIds, loggedInUser));
      dispatch(actions.GetObjectiveActivity(userObjectiveId, employeeIds));
      setAcceptRejectFlag();
      setRejectSelfAssign();
    }
  }, [userObjectiveId, displayCompletion, dispatch, history]);

  useEffect(() => {
    let requestBody = {
      employee: {
        employeeId: employeeIds?.employeeId,
        subGroupId: employeeIds?.subGroupId,
        parentGroupId: employeeIds?.parentGroupId
      },
      manager: {
        employeeId: loggedInUser?.employeeId,
        subGroupId: loggedInUser?.subGroupId,
        parentGroupId: loggedInUser?.parentGroupId,
      },
    }
    employeeIds && dispatch(actions.getUserObjectiveWithManagerEmployeePair(requestBody));
  }, [userObjectiveId]);

  const deleteObjective = (id) => {
    setDisplay(true);
    setObjectiveId(id);
  };

  const ConfirmDeleteObjective = () => {
    if (employeeIds) {
      dispatch(actions.DeleteUserObjective(objectiveId, employeeIds));
      setDisplay(false);
    }
  };

  const cancelHandler = () => {
    let detData = {
      userObjectiveId: objective.userObjectiveId,
      employeeId: objective.employeeId,
      managerId: objective.managerData.employeeId,
      subGroupId: objective.employeeData.subGroupId,
      subGroupName: objective.employeeData.subGroupName,
      parentGroupId: objective.employeeData.parentGroupId,
      parentGroupName: objective.employeeData.parentGroupName,
      managerSubGroupId: objective.managerData.subGroupId,
      managerSubGroupName: objective.managerData.subGroupName,
      managerParentGroupId: objective.managerData.parentGroupId,
      managerParentGroupName: objective.managerData.parentGroupName,
      isDraft: false,
    };
    http
      .put(CANCEL_OBJECTIVE, { definedBy: objective.definedBy }, detData)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          setAcceptRejectFlag();
          dispatch(
            actions.GetAssignedUserObjective(objective.userObjectiveId, objective.employeeData, objective.managerData)
          );
        }
      })
      .catch(() => {});
  };

  const handleCompletionAccept = () => {
    let body = {
      userObjectiveId: userObjectiveId,
      employeeId: objective.employeeId,
      managerId: loggedInUserEmpId,
      subGroupId: objective.employeeData.subGroupId,
      subGroupName: objective.employeeData.subGroupName,
      parentGroupId: objective.employeeData.parentGroupId,
      parentGroupName: objective.employeeData.parentGroupName,
      managerSubGroupId: loggedInUser?.subGroupId,
      managerSubGroupName: loggedInUser?.subGroupName,
      managerParentGroupId: loggedInUser?.parentGroupId,
      managerParentGroupName: loggedInUser?.parentGroupName,
    };
    http
      .put(ACCEPT_OR_REJECT_COMPLETION, { objectiveStatus: "ACCEPT" }, body)
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          setAcceptRejectFlag(true);
          dispatch(
            actions.GetAssignedUserObjective(objective.userObjectiveId, objective.employeeData, objective.managerData)
          );
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const submitRejectCompletion = (comment) => {
    let detData = {
      rejectedComment: comment && comment.trim(),
      userObjectiveId: rowData.userObjectiveId,
      employeeId: rowData.employeeId,
      managerId: rowData.managerId,
      subGroupId: rowData.employeeData.subGroupId,
      subGroupName: rowData.employeeData.subGroupName,
      parentGroupId: rowData.employeeData.parentGroupId,
      parentGroupName: rowData.employeeData.parentGroupName,
      managerSubGroupId: rowData.managerData.subGroupId,
      managerSubGroupName: rowData.managerData.subGroupName,
      managerParentGroupId: rowData.managerData.parentGroupId,
      managerParentGroupName: rowData.managerData.parentGroupName,
    };

    if (!comment.trim()) {
      requiredFieldMissing();
    } else {
      setSubmitDisabled(true);
      http
        .put(ACCEPT_OR_REJECT_COMPLETION, { objectiveStatus: "REJECT" }, detData)
        .then((response) => response.data)
        .then((response) => {
          if (response.status === status.SUCCESS) {
            toast.success(<SlideToast title="Awesome!" message="Successfully rejected!" error={false} />);
            dispatch(
              actions.GetAssignedUserObjective(rowData.userObjectiveId, rowData.employeeData, rowData.managerData)
            );
            dispatch(actions.GetObjectiveActivity(rowData.userObjectiveId, rowData.employeeData));
          } else {
            setSubmitDisabled(false);
          }
        })
        .catch(() => setSubmitDisabled(false));
    }
  };

  const acceptSelfAssignObjective = () => {
    setSubmitDisabled(true);
    if (selfObjectiveData.totalWeightagae == 100) {
      errorToast("You are already assigned with 100% weightage OKR");
      return;
    } else if ((100 - selfObjectiveData.totalWeightagae) < objective.weightage) {
      errorToast("Please check total weightage, as it is exceeding 100%");
      return;
    } else {
      let body = {
        userObjectiveId: userObjectiveId,
        employeeData: {
          employeeId: objective.employeeData.employeeId,
          empName: objective.employeeData.displayName,
          designation: objective.employeeData.designation,
          department: objective.employeeData.department,
          displayPicture: objective.employeeData.dispArr || objective.employeeData.employeeInitial,
          subGroupId: objective.employeeData.subGroupId,
          subGroupName: objective.employeeData.subGroupName,
          parentGroupId: objective.employeeData.parentGroupId,
          parentGroupName: objective.employeeData.parentGroupName,
        },
      };
      http
        .post(APPROVE_SELF_OBJECTIVE, {}, body)
        .then((response) => response.data)
        .then((response) => {
          if (response.status === status.SUCCESS) {
            toast.success(<SlideToast title="Awesome!" message="Accepted Successfully!" error={false} />);
            dispatch(actions.GetAssignedUserObjective(objective.userObjectiveId, objective.employeeData, loggedInUser));
            dispatch(actions.GetObjectiveActivity(objective.userObjectiveId, objective.employeeData));
            setSubmitDisabled(false);
          } else {
            setSubmitDisabled(false);
          }
        })
        .catch(() => {
          setSubmitDisabled(false);
        });
    }
  };

  const submitRejectSelfObjectiveComment = (comment) => {
    let data = {
      rejectedComment: comment && comment.trim(),
      userObjectiveId: objective.userObjectiveId,
      employeeData: {
        employeeId: objective.employeeData.employeeId,
        empName: objective.employeeData.displayName,
        designation: objective.employeeData.designation,
        department: objective.employeeData.department,
        displayPicture: objective.employeeData.dispArr || objective.employeeData.employeeInitial,
        subGroupId: objective.employeeData.subGroupId,
        subGroupName: objective.employeeData.subGroupName,
        parentGroupId: objective.employeeData.parentGroupId,
        parentGroupName: objective.employeeData.parentGroupName,
      },
    };
    if (!comment.trim()) {
      requiredFieldMissing();
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
      http
        .put(REJECT_SELF_OBJECTIVE, {}, data)
        .then((response) => response.data)
        .then((response) => {
          if (response.status === status.SUCCESS) {
            toast.success(<SlideToast title="Awesome!" message="Successfully rejected!" error={false} />);
            dispatch(actions.GetAssignedUserObjective(objective.userObjectiveId, objective.employeeData, loggedInUser));
            dispatch(actions.GetObjectiveActivity(objective.userObjectiveId, objective.employeeData));
            setSubmitDisabled(false);
          } else {
            setSubmitDisabled(false);
          }
        })
        .catch(() => setSubmitDisabled(false));
    }
  };

  //when manager accept or reject Completion and submit rating then isSubmitted true
  if (objective && objective.isSubmitted === false && objective.rejectedComment !== null) {
    isSubmitFlag = false;
  } else if (objective && objective.isSubmitted === true) {
    isSubmitFlag = true;
  }

  let rowData = null;
  if (objective) {
    rowData = {
      rejectComment: objective.rejectedComment,
      isSubmitted: objective.isSubmitted,
      userObjectiveId: userObjectiveId,
      employeeId: objective.employeeId,
      employeeData: objective.employeeData,
      managerIdLogin: loggedInUser,
      managerId: objective.managerData?.employeeId,
      managerData: objective.managerData,
    };
  }

  const universityObjective = objective && objective.definedBy === "UNIVERSITY" ? true : false;
  const CompletionText = `Wow! ${
    objective && objective.employeeData && objective.employeeData.displayName
  } has completed this objective, You can Approve the OKR and provide Rating at a time of Performance Cycle. If you are not sure about the completion, please click "Reject".`;
  const selfAssignText = `${
    objective && objective.employeeData && objective.employeeData.displayName
  } has Self Assigned this objective, please click "Accept" in order to Assign this objective. If you are not sure about this, please click "Reject"`;

  if (isLoading) {
    return <Spinner />;
  }

  if (resourceNotFound) {
    return <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />;
  }

  return (
    <div className={style.assignobjectives_wrapper}>
      <SubNavigation type="Objectives" />
      <Container>
        <Row>
          <Col md={6} className="col-12">
            <h1 className="page-title pb-0">Objectives</h1>
            <span className="st-breadcrumb">
              Objectives
              <span>
                <Link to={routePath.TEAMS_OBJECTIVE}>Team&apos;s Objectives</Link>
              </span>
              <span>{objective && objective.employeeData && objective.employeeData.displayName}</span>
            </span>
          </Col>
          <Col lg={12}>
            {objective &&
              objective.isNotify &&
              !objective.completionDate &&
              ((acceptRejectFlag === undefined && isSubmitFlag === "-") ||
                ((objective.status === objectiveStatusValue.PENDING_APPROVAL ||
                  objective.status === objectiveStatusValue.EXPIRED) &&
                  acceptRejectFlag === undefined)) &&
              !objective.isAccepted && (
                <AcceptAndReject
                  /* disabled as we received request from QA side */
                  disabled={true}
                  // added this flag to hide accept button as fix of prod issue 
                  isShow={false}
                  text={CompletionText}
                  handleAccept={handleCompletionAccept}
                  handleReject={() => setAcceptRejectFlag(false)}
                />
              )}

            {objective &&
              objective.status === objectiveStatusValue.DRAFTED &&
              (objective.managerData && objective.managerData.employeeId) === loggedInUserEmpId &&
              !(objective.isSelfObjectiveAccept === false) &&
              !rejectSelfAssign && (
                <AcceptAndReject
                  disabled={submitDisabled}
                  // added this flag to hide accept button as fix of prod issue 
                  isShow={true}
                  text={selfAssignText}
                  handleAccept={acceptSelfAssignObjective}
                  handleReject={() => setRejectSelfAssign(true)}
                />
              )}

            {/* below commentSubmit is for rejecting self assign objective */}
            {rejectSelfAssign && !(objective.isSelfObjectiveAccept === false) && (
              <CommentSubmit
                submitDisabled={submitDisabled}
                displayPicture={loggedInUser?.displayPicture || loggedInUser?.empInitial}
                textHeading="Give reason behind rejecting this Objective:"
                commentBoxHeadingText="Share your views"
                submitComment={submitRejectSelfObjectiveComment}
                cancelHandler={() => setRejectSelfAssign()}
                placeholder="Add a comment"
                textLength={500}
              />
            )}

            {/* below commentSubmit is for rejecting completed objective */}
            {acceptRejectFlag === false &&
              (objective.rejectedComment == null ||
                objective.status === objectiveStatusValue.PENDING_APPROVAL ||
                objective.status === objectiveStatusValue.EXPIRED) &&
              objective.isNotify && (
                <CommentSubmit
                  submitDisabled={submitDisabled}
                  displayPicture={loggedInUser?.displayPicture || loggedInUser?.empInitial}
                  textHeading="Give reason behind rejecting this Objective:"
                  commentBoxHeadingText="Share your views"
                  submitComment={submitRejectCompletion}
                  cancelHandler={() => setAcceptRejectFlag()}
                  placeholder="Add a comment"
                  textLength={500}
                />
              )}
          </Col>
          {/* This will reflect on when user accept the objective */}

          {objective && !objective.isSubmitted && objective.isAccepted && !universityObjective && (
            <Col lg={12}>
              <div className={style.mng_obj_notification}>
                <span className={style.notify_icon}>
                  <ReactSVG src={`${IC_NOTIFY}`} />
                </span>
                <p>You have accepted the completion of the Objective, kindly rate the Key Results now. </p>
                <span className={style.note_txt}>
                  Note: Below you have to click on Submit in order to Accept the Objective or else it will show Pending.
                </span>
              </div>
            </Col>
          )}

          {/* This will reflect when objective by university */}
          {objective &&
            (acceptRejectFlag === true || isSubmitFlag === true || objective.isAccepted) &&
            universityObjective && (
              <RatingKeyResultsUni objective={objective} loggedInUser={loggedInUser} cancelHandler={cancelHandler} />
            )}

          {objective && objective.employeeData && (
            <ObjectiveHeading
              loginEmployee={loggedInUserEmpId}
              user={objective.employeeData}
              deleteObjective={deleteObjective}
              objective={objective}
              created={true}
            />
          )}
          <Col xl={8}>
            {objective && objective.description && <ObjectiveDescription description={objective.description} />}

            {universityObjective ? (
              objective && <KeyAccordion objective={objective} />
            ) : acceptRejectFlag !== true && isSubmitFlag !== true && objective && !objective.isAccepted ? (
              <ObjectiveKeyResults keyPoints={objective.keyPoints} assign={true} />
            ) : (
              <RatingKeyResults rowData={objective} loggedInUser={loggedInUser} cancelHandler={cancelHandler} />
            )}

            {objective && activities && (
              <ObjectiveActivities
                activities={activities}
                user={objective.employeeData}
                handleRejectMessagePopup={(data) => {
                  setRejectModel(true);
                  setActivityData(data);
                }}
              />
            )}
          </Col>
          <Col xl={4} lg={4}>
            {objective && objective.managerData && <ObjectiveOwner owner={objective.managerData} />}
            {objective && objective.employeeData && <ObjectiveAssignee user={objective.employeeData} />}
            {objective && objective.tags && objective.tags.length > 0 && <SideBarTags tags={objective.tags} />}
            <ConfirmationPopup
              show={display}
              submit={() => ConfirmDeleteObjective()}
              hide={() => setDisplay(false)}
              headerText={headerText}
              subText={subText}
            />
          </Col>
        </Row>
      </Container>
      {rejectModel && (
        <RejectMessageModal
          activityData={activityData}
          objective={objective}
          reasonOfComment={
            activityData.whatChanged === "OBJ_FEEDBACK_STATUS"
              ? 'has marked objective as "INVALID"'
              : `has rejected your objective "${objective && objective.title}"`
          }
          hide={() => setRejectModel(false)}
          show={rejectModel}
        />
      )}
    </div>
  );
}
