export const backGroundColor = ["#FF5479", "#CC54FF", "#54A1FF", "#F9C03C", "#13BB8D", "#5C59E3", "#75BA30"];
/* added for temporary will change once we received correct names */
export const objTitle = ["a","b","c"];

export const moodOrder = {
  "Awesome!": 5,
  "Pretty Good!": 4,
  "Completely Okay!": 3,
  "Somewhat Bad!": 2,
  "Really Terrible!": 1,
};

export const workLoadOrder = {
  Smooth: 1,
  Reasonable: 2,
  "Needs Attention": 3,
  Demanding: 4,
  "Bit Tough": 5,
  Hard: 6,
  Severe: 7,
  Difficult: 8,
  "Nearly Exhausted": 9,
  Exhausted: 10,
};

export const locationOrder = {
  Ahmedabad: 1,
  Pune: 2,
  Bengaluru: 3,
  "Santa Clara": 4,
  "New York": 5,
  London: 6,
  Germany: 7,
};

export const checkinStatusOrder = {
  PENDING_FROM_MEMBER: 1,
  PENDING_FOR_REVIEW: 2,
  REVIEWED: 3,
};

export const bandForSelfAssignObjective = ["5","6B","I3","TRUE","M4","7","JL5","5B","5A","M5","A1","E1","A4","I1","E3","A3","C1","B2","6A","Executive","FALSE","S8","3B","M6","Director","D1","I2","Z2","E2","6A(Infostretch)","4(Infostretch)","-"];
export const bandForSelfAssignObjectiveUK = ["6B","I3","TRUE","M4","7","JL5","M5","A1","E1","A4","I1","E3","A3","C1","B2","6A","Executive","FALSE","S8","3B","M6","Director","D1","I2","Z2","E2","6A(Infostretch)","4(Infostretch)","-"];

export const supportedFiles =
  "image/png, .jpeg, .jpg, application/pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .odf, .eml, .zip, .rar, .msg, .pdf ";

export const blinkMenu = ["Self Assessment", "Reporting Assessment", "Reviewing Assessment", "Campaign"];
export const blinkMenuQuiz = ["Self Assessment", "Reporting Assessment", "Reviewing Assessment", "Campaign","Quiz"];

export const indiaBannerText = [
  "Ahmedabad : Ayush Mathuria – 9624010002 ; Twinkle Nanavati - 9879837614 ; Yatendra Vijay – 8980000360",
  "Bangalore : Bhavika Bhetariya – 8600143747 ; Priyanka Sahni – 9860061909 ; Yatendra Vijay – 8980000360",
  "Pune : Nitin Nikumbh – 7720008395 ; Priyanka Sahni – 9860061909 ; Yatendra Vijay – 8980000360",
];

export const usBannerText = ["IT – Tariq Chaudhary (US/UK), HR – Bina Patel (US), HR – Hinal Shah (UK)"];

// export const bannerHeading = "Please refer vedio tutorial link:'http://www.sampletutoriallink.com/'" +"     " + "";
export const bannerUrl = 'Please refer "Information Hub" for video tutorials.';
export const bannerNewText = 'The window for assigning Q2’24 OKRs is now open. For more details, Please refer to the video tutorial available in the “Information Hub".';
export const datesText = "Important Dates for Q1'24 Check-In Review - Self Assessment 18th April - 23th April, Reporting Manager Assessment - 24th April - 30th April, Reviewing Manager Assessment - 24th April - 07th May";

export const wmgBannerText = 'Get ready to turbocharge your career with Accelerate, a framework designed for you to take charge of your growth at Apexon.';
export const prismForceText = 'To update / review skills, please navigate to Accelerate by';
export const prismForceUrl = 'https://apexon.prismforce.com/';

export const okrRedirectionText = 'Features have been relocated to a new tool: Please log in to PeopleHub to update your OKRs and access Performance details.';
export const okrRedirectionLink = 'https://apexon-peoplehub.darwinbox.com/ms/pms/dashboard/overview';

export const country = {
  UNITED_STATE: "United States",
  UNITED_KINGDOM: "United Kingdom",
};

export const placeHolder = {
  SEARCH_TEAM: "Search Team Member",
};

export const noData = {
  WE_DONT_HAVE_THE_DATA: "It seems we don’t have the data you’re looking for",
  QUIZ_NOT_ATTEMPTED_DONT_HAVE_THE_DATA: "Quiz is closed. You have not attempted the Quiz.",
  MEMBER_NOT_FOUND: "Oops! Member not Found",
  MEMBER_NOT_FOUND_DESCRIPTION: "It Seems you don't have the member you're looking for",
  DATA_IS_NOT_YET_FOUND: "The data is not yet found",
  NO_RECORD_FOUND: "Oops! No records found",
};

export const title = {
  CERTIFICATION_NAME: "Certification Name",
};

export const objectiveStatusValue = {
  COMPLETE: "COMPLETED",
  DRAFTED: "DRAFTED",
  PENDING_APPROVAL: "PENDING_APPROVAL",
  EXPIRED: "EXPIRED",
  ASSIGNED: "ASSIGNED",
  INVALID: "INVALID",
  INPROGRESS: "INPROGRESS",
  REJECTED: "REJECTED",
  STRIKED_OUT: "STRIKED_OUT",
};

export const status = {
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  NOTIFIED: "NOTIFIED",
  FAILED: "FAILED",
  PUBLISHED: "PUBLISHED",
  SUBMIT: "SUBMIT",
  INVALIDATE: "INVALIDATE",
};

export const checkInStatus = {
  EXPIRED: "EXPIRED",
  INVALID: "INVALID",
  COMPLETED: "COMPLETED",
  PENDING_APPROVAL: "PENDING_APPROVAL",
  DRAFTED: "DRAFTED",
  ASSIGNED: "ASSIGNED",
  INPROGRESS: "INPROGRESS",
  REJECTED: "REJECTED",
  REVIEWED: "REVIEWED",
  PENDING_FROM_MEMBER: "PENDING_FROM_MEMBER",
};

export const popupText = {
  DELETE_CONFIG: "Sure you want to delete configuration?",
  DELETE_CONFIG_SUBTEXT: "We recommend editing the configuration. Once deleted, you won't be able to undo it.",
};

export const buttonText = {
  CONFIRM: "Confirm",
  CREATE: "Create",
  SEARCH: "Search",
  EDIT: "Edit",
  CANCEL: "Cancel",
  CLEAR: "Clear",
  UPDATE: "Update",
};

export const competencyLevel = {
  0: "Unexplored",
  1: "Beginner",
  2: "Intermediate",
  3: "Advanced",
};

export const menuText = {
  CHECK_IN: "OKR Progress",
  ONE_ON_ONE: "1-on-1's",
  CURRENT_CHECKIN: "Current OKR Progress",
  MY_TEAM: "My Team",
  MY_HISTORY: "My History",
  GLOBAL_FEEDBACK: "Global Feedback",
  OBJECTIVES: "Objectives",
  MY_OBJECTIVES: "My Objectives",
  TEAM_OBJECTIVES: "Team's Objectives",
  ALL_OBJECTIVES: "All Objectives",
  PERFORMANCE: "Performance",
  DASHBOARD: "Dashboard",
  PROJECT_DEALLOCATION: "Project Deallocation",
  MMG: "WMG",
  MY_CERTIFICATIONS: "My Certifications",
  MY_SKILLS: "My Skills",
  MY_TECHNICAL_ROLE_CHANGE: "My Technical Role Change",
  HR: "HR",
  ADMIN: "Admin",
  FEEDBACK: "Feedback",
  EMPLOYEE_FEEDBACK_HISTORY: "Global Feedback History",
  CAMPAIGN: "Campaign",
  CAMPAIGN_DETAILS: "Campaign Details",
  CAPABILITY_DEVELOPMENT: "Capability Development",
  SELF_ASSESSMENT: "Self Assessment",
};

export const errorMessage = {
  SOMETHING_WRONG: "Something wrong!",
  REQUIRED_FIELD_MISSING: "Required field missing!",
  PLEASE_SELECT_TEMPLATE_BEFORE_PUBLISH: "Please select template before publish",
  PLEASE_SELECT_DATE: "Please Select start Date and end Date",
  NOT_AUTHORIZED: "Not Authorized",
  MAXIMUM_FILE_SIZE: "Maximum file size is 2MB",
  FILE_TYPE: "File Type not allowed.",
  FILL_PROPER: "Please fill proper details",
  FEEDBACK_SUBMIT: "Error in feedback submit",
  SELECT_NEW_RPM: "Please Select New Reporting Manager",
  SELECT_NEW_RVM: "Please Select New Reviewing Manager",
  SELECT_MANAGER: "Please select manager",
  REPLACE_MANAGER: "Error in replace manager",
  CREATE_ASSESSMENT: "Error in create assessment",
  DELETE_ASSESSMENT: "Error in delete assessment",
  SELECT_FILTER_TYPE: "Please select filter type",
  MINIMUM_TWO_CYCLE: "Please select minimum two cycle",
  SELECT_ASSIGNEE: "Please select Assignee",
  SELECT_DATE: "Please select Date",
  ADD_COMMENT: "Please add a comment",
  SELECT_CYCLE: "Please Select Cycle",
  DATE_FORMAT:
    "Date format should be MM/DD/YYYY(For Ex:08/20/2020) Or Start date should be less than End date(vice versa)",
};

export const successMessage = {
  SUCCESSFULLY_SUBMITTED: "Successfully Submitted",
  SUCCESSFULLY_CREATED: "Successfully Created",
  DELETE_SUCCESSFULLY: "Deleted Successfully",
  MAPPING_SUCCESSFULLY: "Mapping Successfully",
  FILE_DOWNLOADED: "File downloaded successfully.",
  PUBLISHED: "Published Successfully",
  FEEDBACK_SUBMITTED: "Feedback has been submitted.",
  ASSESSMENT_CREATED: "Assessment created successfully",
  ASSESSMENT_DELETED: "Assessment deleted successfully",
  OBJECTIVE_CREATED: "Objective has been created.",
  CYCLE_EDITED: "Successfully edited cycle",
  SAVE_COMMENT: "Save Comment",
  DOWNLOAD: "Download Successfully",
  SELECT_TEMPLATE: "Please Select Template",
};

export const text = {
  MANAGER_RESPONSE_SUB_TEXT: "Here is what you want to say to team member",
  MANAGER_RESPONSE_HEADING: "Manager's View",
  MANAGER_RESPONSE_SUB_TEXT_IN_EMPLOYEE: "Here Manager has given their views",
};

export const btnName = {
  SELECT_TEMPLATE: "Select Question Template",
  CREATE_NEW_QUESTION: "Create New Questions",
  MAP_CAPABILITY_TEMPLATE: "Map Capability Template",
  EXPORT: "Export",
  APPLY: "Apply",
  SUBMIT: "Submit",
  CLEAR: "Clear",
  CANCEL: "Cancel",
  CLEAR_ALL: "Clear All",
  SEARCH: "Search",
};

export const MonthlyCheckinTexts = {
  MONTHLY_CHECK_IN: "Monthly Check-in :",
  MONTHLY_CHECK_IN_HR_TITLE: "Monthly Check-in Feedback",
  CHECK_IN_PERIOD: "Check-in",
  DURATION: "Duration:",
  SUBMITTED_TO: "Submitted to :",
  SUBMISSION_DATE: "Submission Dates :",
  PENDING_TO: "Pending to :",
  SUBMIT_YOUR_FEEDBACK :"Submit Your Feedback",
  QUESTIONS :"Questions :",
  RECENT_MONTHLY_CHECK_IN_SUBMISSIONS: "Recent Monthly Check-In Submissions",
  TOTAL_SUBMISSIONS: "Total Submissions:",
  SELECT_EMPLOYEES: "Select Employees",
  EMPLOYEES: "Employees",
  SUBMIT_YOUR_MONTHLY_CHECK_IN: "Submit Your Monthly Check-in",
  MONTHLY_CHECK_IN_COMPLETED_TITLE: "check-ins for this month have been ended.",
  MONTHLY_CHECK_IN_COMPLETED_NOTE: "Please await the opening of the next check-in window.",
  NOTE_FOR_MANAGER : "Note : To send a individual message to a specific employee, just exclude their name from the list of common comments.",
  NOTE: "Note : Our monthly performance assessment check-in module offers a structured platform for regular feedback and evaluation, fostering continuous improvement and alignment with organizational goals.",
  months : [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  CHECK_INS_PENDING: "check-in pending",
  CHECK_IN_SUBMISSIONS: "check-in submissions",
};

export const tooltipText = {
  UP_DOWN_STABLE: "The Up, Down and stable symbol represent the graph of employee according to their performance",
};
