import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import style from "./AssignObjectives.module.scss";
import { IC_SETTINGS, IC_ASSIGN_OBJECTIVE, IC_DELETE_2, IC_EDIT_OBJECTIVE } from "const/imgCost";
import { dateFormat1 } from "util/dateUtils";

export default function ObjectiveHeading(props) {
  const { updatedDate, objectiveId, createdDate, title, weightage } = props.objective;
  return (
    <Col className="col-12">
      <div className={["custom-tabs custom-tabs-full-width tab-content-dashboard", style.allobjectives_tabs].join(" ")}>
        <div className="box ">
          <div className="box-heading-wrapper">
            <div className="box-heading text-break">
              <h2>{title}</h2>
            </div>
            {props.created && (
              <div className={style.settings_icon}>
                <div className="action-dropdown-wrapper">
                  <DropdownButton alignRight title={<ReactSVG src={`${IC_SETTINGS}`} alt="" />} variant="a" disabled={true}>
                    <Link
                      to={`/objectives/all-objectives/assign-objective/${objectiveId}`}
                      style={{ color: "#000" }}
                      className={["dropdown_item dropdown-item", style.dropdown_threeitem].join(" ")}
                    >
                      <div className="dropdown_icon">
                        <ReactSVG src={`${IC_ASSIGN_OBJECTIVE}`} alt="" />
                      </div>
                      Assign Objective
                    </Link>

                    <Link
                      to={`/objectives/edit-objective/${objectiveId}`}
                      style={{ color: "#000" }}
                      className={["dropdown_item dropdown-item", style.dropdown_threeitem].join(" ")}
                    >
                      <div className="dropdown_icon">
                        <ReactSVG src={`${IC_EDIT_OBJECTIVE}`} alt="" />
                      </div>
                      Edit Objective
                    </Link>

                    <Dropdown.Item
                      className={style.dropdown_threeitem}
                      onClick={() => props.deleteObjective(objectiveId)}
                    >
                      <div className="dropdown_icon">
                        <ReactSVG src={`${IC_DELETE_2}`} alt="" />
                      </div>
                      <div className="dropdown_item">Delete</div>
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            )}
          </div>
          <div className="box-inner-pad pb-0">
            <div className="form-group">
              <Row>
              <Col xl={2} md={3} lg={2} className={style.assign_objective_duration}>
                  <span>OKR Weightage</span>
                  {weightage ? weightage + "%" : "-"} 
                </Col>
                <Col xl={2} md={3} lg={2} className={style.assign_objective_duration}>
                  <span>Created On</span>
                  {dateFormat1(createdDate)}
                </Col>
                <Col xl={2} md={3} lg={2} className={style.assign_objective_duration}>
                  <span>Last Modified</span>
                  {dateFormat1(updatedDate)}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}
