import React, { useEffect } from "react";
import styles from "./infoBannerContact.module.scss";
import { Container, Row, Col } from "react-bootstrap";

/**
 * PROPS : revirewer
 */
export default function InfoBannerContact({ text, heading, linkUrl, page }) {
  // useEffect(() => {
  //   var counter = 0;
  //   var elem = document.getElementById("changing");
  //   // var inst = setInterval(change, 8000);

  //   function change() {
  //     elem.innerHTML = text[counter];
  //     counter++;
  //     if (counter >= text.length) {
  //       counter = 0;
  //       // clearInterval(inst); // uncomment this if you want to stop refreshing after one cycle
  //     }
  //   }

  //   document.getElementById("info_banner_wrapper").addEventListener("click", function () {
  //     change();
  //   });
  // });

  return (
    <section className={page === "Objectives" ? styles.info_banner_wrapper_margin : styles.info_banner_wrapper} id="info_banner_wrapper">
      <Container>
        <Row>
          <Col md={12} className="text-center">
            <div className={styles.scroll_container}>
              <div className={styles.scroll_text}>
                <p>
                  {/* {heading} commented due to new text CR by HR */}
                  <span className={styles.datesText}>{text}</span>
                  {linkUrl && <a href={linkUrl} target="__blank">Clicking here</a>}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
