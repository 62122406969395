import React from "react";
import Myobjectives from "./MyObjectives/MyObjectives";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import InfoBannerContact from "components/InfoContactBanner/InfoBannerInfo";
import { okrRedirectionText, okrRedirectionLink } from "const/constValue";

const Objectives = () => {
  return (
    <div>
      <SubNavigation type="Objectives" page="Objectives"/>
      <InfoBannerContact text={okrRedirectionText} linkUrl={okrRedirectionLink} page="Objectives" />
      <Myobjectives />
    </div>
  );
};

export default Objectives;
