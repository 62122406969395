import React, { forwardRef, useEffect, useState } from "react";
import {
  Button,
  Form,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import style from "./OKRTemplate.module.scss";
import { buttonText, popupText, noData } from "const/constValue";
import { ReactSVG } from "react-svg";
import { IC_DELETE, IC_EDIT_OBJECTIVE, IC_VIEW_DETAILS, IC_INPUT_SEARCH } from "const/imgCost";
import ConfirmationPopup from "components/Objectives/ConfirmationPopup";
import InformationPopup from "./InformationPopup/InformationPopup";
import {
  getPredefinedObjectiveAdminView,
  deletePredefinedObjectiveAdminView,
} from "store/actions";
import Pagination from "shared/Pagination/Pagination";
import config from "util/config";
import moment from "moment";
import Spinner from "shared/Spinner/Spinner";
import NoDataFound from "../../NodataFound/NoDataFound";


const OKRTemplateList = forwardRef(({ onEdit }, ref) => {
  const dispatch = useDispatch();
  const { predefinedObjectives, totalCount, isLoading } = useSelector(
    (state) => state.okrTemplate
  );
  const [searchText, setSearchText] = useState("");
  const [showPopup, setShowPopup] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  const [tablePopupData, setTablePopupData] = useState();
  const [bandWiseObjectiveId, setBandWiseObjectiveId] = useState();
  let className = "form-control-rounded form-search-item";
  if (searchText && searchText.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }

  useEffect(() => {
    dispatch(getPredefinedObjectiveAdminView("1", "10"));
  }, []);

  const handleDelete = (item) => {
    setBandWiseObjectiveId(item.bandWiseObjectiveId);
    setShowPopup(true);
  };

  const handleEdit = (item) => {
    if (onEdit) {
      onEdit(item);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleDeleteConfirm = () => {
    dispatch(deletePredefinedObjectiveAdminView(bandWiseObjectiveId));
    setShowPopup(false);
    setBandWiseObjectiveId("");
  };

  const handleCancel = () => {
    setShowPopup(false);
  };

  const onChangePage = (currentPage, pageSize) => {
    dispatch(
      getPredefinedObjectiveAdminView(
        currentPage.toString(),
        pageSize.toString(),
        searchText
      )
    );
  };

  //handle search input
  const handleSearchInputChange = (e) => {
    if(e.target.value.length > 0){
      setSearchText(e.target.value);
    }else{
      handleClose();
    }
  };

  //handle clear 
  const handleClose = () => {
    setSearchText("");
    dispatch(getPredefinedObjectiveAdminView("1", "10"));
  }

  //handle Enter key
  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      dispatch(getPredefinedObjectiveAdminView("1", "10", searchText));
    }
  }

  return (
    <>
      {isLoading && <Spinner />}
      <div
        className={[style.organization_history_wrapper, "box"].join(" ")}
        ref={ref}
      >
        <div className="box-heading-wrapper">
          <div className="box-heading">
            <h2>OKR Template List</h2>
            <h3>Revise or remove the template as needed</h3>
          </div>
        </div>
        <div className={style.search_bar}>
          <Row>
            <div className="d-flex col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <Form className={style.allobjective_searchbar}>
                <Form.Group className="form-postfix mb-0 w-100">
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="Search"
                    name="searchBox"
                    className={className}
                    onChange={handleSearchInputChange}
                    value={searchText}
                    onKeyPress={handleOnKeyPress}
                  />
                  <Button
                    className="close-button"
                    type="reset"
                    onClick={handleClose}
                  >
                    <span className="close-icon"></span>
                  </Button>
                  <div className="form-postfix-icon">
                    <img src={IC_INPUT_SEARCH} title="" alt="" />
                  </div>
                </Form.Group>
              </Form>
            </div>
          </Row>
        </div>
        <div className="box-light-gradient"></div>
        <div className="box-inner-pad p-0">
          <div
            className={[
              style.defined_roles_table,
              "custom-table",
              "table-theme-1",
            ].join(" ")}
          >
            {predefinedObjectives.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th className={style.objecttive_Name}>Objective Name</th>
                    <th className={style.weightage}>Objective Weightage</th>
                    <th className={style.band_mapped}>Band Mapped</th>
                    <th className={style.date_time}>Updated Date & Time</th>
                    <th className={style.update}>Updated By</th>
                    <th className={style.action}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {predefinedObjectives.map((item) => (
                    <tr
                      key={item.bandWiseObjectiveId}
                      className={
                        item?.managerListDTO ? style.selectedManagerStyle : ""
                      }
                    >
                      <td>{item.title}</td>
                      <td>{item.weightage}</td>
                      <td>{item.band}</td>
                      <td>
                        {item.updatedDate === null
                          ? "-"
                          : moment(item.updatedDate).format(
                              config.dates.tertiary
                            )}
                      </td>
                      <td>
                        {item.updatedBy === null
                          ? "-"
                          : item.updatedBy.displayName}
                      </td>
                      <td>
                        <div className={style.action}>
                          <span
                            onClick={() => {
                              setOpenPopup(true);
                              setTablePopupData(item);
                            }}
                          >
                            <ReactSVG
                              src={`${IC_VIEW_DETAILS}`}
                              alt=""
                              title="info"
                            />
                          </span>
                          <span onClick={() => handleEdit(item)}  aria-disabled={true}>
                            <ReactSVG
                              src={`${IC_EDIT_OBJECTIVE}`}
                              alt=""
                              title="edit"
                            />
                          </span>
                          <span onClick={() => handleDelete(item)}>
                            <ReactSVG
                              src={`${IC_DELETE}`}
                              alt=""
                              title="delete"
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
            )}
          </div>
        </div>
        <InformationPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          item={tablePopupData}
        />
        <ConfirmationPopup
          show={showPopup}
          hide={handleCancel}
          submit={handleDeleteConfirm}
          headerText={popupText.DELETE_CONFIG}
          subText={popupText.DELETE_CONFIG_SUBTEXT}
          submitBtnText={buttonText.CONFIRM}
        />
      </div>
      {totalCount > 0 && (
        <Pagination totalItem={totalCount} onChangePage={onChangePage} />
      )}
    </>
  );
});

export default OKRTemplateList;
