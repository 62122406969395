import { Button, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { ObjectiveTypeOption } from "const/options";
import { status } from "const/constValue";
import { buttonText } from "const/constValue";
import style from "./OKRTemplate.module.scss";
import Select from "react-select";
import MultiSelect from "react-multi-select-component";
import { errorToast, filterOptions, successToast } from "util/general";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "shared/Spinner/Spinner";
import { getManagerListPredefinedObjective } from "store/actions/okrTemplate";
import { OKR_TEMPLATE_ACTIONS } from "store/actions/actionTypes";

const initialOkrObject = {
  title: "",
  weightage: "",
  band: "",
  description: "",
  objectiveType: "",
  managerListDTO: null,
};

export default function OKRTemplateCreateEdit({ editData, onSubmit, onClear }) {
  const [OKR, setOKR] = useState(initialOkrObject);
  const [error, setError] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [chars, setChars] = useState(500);
  const [selectedOption, setSelectedOption] = useState(null);
  const [managerListOption, setMangerList] = useState(null);
  const {
    isWeightage,
    managerOption,
    isLoading,
    responseStatus,
    message
  } = useSelector((state) => state.okrTemplate);
  const dispatch = useDispatch();

  useEffect(()=>{
    if(responseStatus){
    if(responseStatus === status.SUCCESS){
      successToast(message);
      handleClear();
    }else{
      errorToast(message);
    }
    dispatch({type: OKR_TEMPLATE_ACTIONS.SET_TOAST_MESSAGE, payload: {responseStatus:null, message: null, isWeightage}})
  }
  },[responseStatus, message]);

  useEffect(() => {
    if (editData) {
      if (editData?.managerListDTO) {
        editData.managerListDTO = editData.managerListDTO.map((manager) => {
          const {
            managerId,
            managerName,
            managerParentGroupId,
            managerSubGroupId,
          } = manager;
          return {
            value: managerId,
            label: managerName,
            managerParentGroupId: managerParentGroupId,
            managerSubGroupId: managerSubGroupId,
          };
        });
      }
      const { title, weightage, band, description, objectiveType, managerListDTO } = editData;
      setOKR({
        ...editData,
        title: title || initialOkrObject.title,
        weightage: weightage || initialOkrObject.weightage,
        band: band || initialOkrObject.band,
        description: description || initialOkrObject.description,
        objectiveType: { value: objectiveType, label: objectiveType } || initialOkrObject.objectiveType,
        managerListDTO: managerListDTO || initialOkrObject.managerListDTO,
      });
      setIsEditMode(true);
      setChars(500 - description?.length);
    }
  }, [editData]);

  useEffect(() => {
    dispatch(getManagerListPredefinedObjective());
  }, []);

  const handleChange = (key, value) => {
    if (key === "weightage" && value !== "") {
      if (/^\d+$/.test(value)) {
        const intValue = parseInt(value);
        if (intValue >= 1 && intValue <= 100) {
          setOKR({ ...OKR, [key]: intValue });
        } else {
          setOKR({ ...OKR, [key]: "" });
        }
      } else {
        setOKR({ ...OKR, [key]: "" });
      }
    } else if(key === "managerListDTO" && value.length === 0) {
        setOKR({ ...OKR, [key]: null });
    } else {
      setOKR({ ...OKR, [key]: value });
      if (key === "description") {
        setChars(500 - value.length);
      }
    }
  };

  const handleClear = () => {
    setOKR(initialOkrObject);
    setSelectedOption(null);
    setMangerList(null);
    setIsEditMode(false);
    onClear();
    setError(false);
    setChars(500);
  };

  const handleSelectChange = (key, value) => {
    setSelectedOption(value);
    handleChange(key, value.value);
  }

  const formatManagersList = (value) => {
    return (
      value &&
      value.map((manager) => {
        const {
          value,
          label,
          managerId,
          managerName,
          managerParentGroupId,
          managerSubGroupId,
        } = manager;
        return {
          managerId: value || managerId,
          managerName: label || managerName,
          managerParentGroupId: managerParentGroupId,
          managerSubGroupId: managerSubGroupId,
        };
      })
    );
  };

  const handleManagerChange = (key, value) => {
    setMangerList(value);
    const newArrayOfObj = formatManagersList(value);
    handleChange(key, newArrayOfObj);
  }

  const handleSubmit = () => {
    if (OKR.title && OKR.band && OKR.weightage && OKR.description && OKR.objectiveType) {
      OKR.managerListDTO = formatManagersList(OKR.managerListDTO);
      onSubmit(isEditMode, OKR);
    } else {
      errorToast("Required field are missing")
      setError(true);
    }
  };

  return (
    <>
    {isLoading && <Spinner />}
    <div className="box pb-2">
      <div className="box-heading-wrapper">
        <div className="box-heading">
          <h2>Create OKR Template</h2>
          <h3>
            Add require fields to create an OKR template
          </h3>
        </div>
      </div>
      <div className="box-inner-pad">
        <div className="radio-wrapper d-flex dropdown-multiselect mb-4">
          <Form.Group>
            <Form.Label className={style.dd_title}>
              Objective Name<span className="text-danger">*</span>
            </Form.Label>
            <div className={["tags_wrapper", style.role_dropdown].join(" ")}>
              <input
                type="text"
                placeholder="Name"
                className={["custom-default-dd-box", error && !OKR?.title && "error-box", style.custom_input].join(
                  " "
                )}
                maxLength="200"
                value={OKR?.title}
                onChange={(event) => handleChange("title", event.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label className={style.dd_title}>
              Objective Weightage<span className="text-danger">*</span>
            </Form.Label>
            <div className="tags_wrapper">
              <input
                type="text"
                placeholder="Weightage"
                className={["custom-default-dd-box", error && !OKR?.weightage && "error-box", isWeightage && "error-box", style.custom_input].join(
                  " "
                )}
                value={OKR?.weightage}
                onChange={(event) =>
                  handleChange("weightage", event.target.value)
                }
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label className={style.dd_title}>
              Band Mapped<span className="text-danger">*</span>
            </Form.Label>
            <div className="tags_wrapper">
              <input
                type="text"
                placeholder="Band"
                className={["custom-default-dd-box", error && !OKR?.band && "error-box", style.custom_input].join(
                  " "
                )}
                maxLength="50"
                value={OKR?.band}
                onChange={(event) => handleChange("band", event.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label className={style.dd_title}>
              Objective Description<span className="text-danger">*</span>
            </Form.Label>
            <div className="tags_wrapper">
              <Form.Control
                as="textarea"
                maxLength="500"
                placeholder="Description"
                className={["custom-default-dd-box", error && !OKR?.description && "error-box", style.custom_input].join(
                  " "
                )}
                value={OKR?.description}
                onChange={(event) => handleChange("description", event.target.value)}
              />
              <div className="checkin-question-info mt-2"> {chars} Characters Left</div>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label className={style.dd_title}>
              Objective Type<span className="text-danger">*</span>
            </Form.Label>
            <div className="tags_wrapper">
              <Select
                options={ObjectiveTypeOption}
                className={["custom-default-dd-box", error && !OKR?.objectiveType && "error-box"].join(" ")}
                classNamePrefix="select"
                placeholder="Select..."
                value={selectedOption ? selectedOption : OKR?.objectiveType}
                onChange={(event) => handleSelectChange("objectiveType", event)}
              />
            </div>
          </Form.Group>
          {managerOption && <Form.Group>
            <Form.Label className={style.dd_title}>
              Maneger's List
            </Form.Label>
            <div className="tags_wrapper">
              <MultiSelect
                options={managerOption}
                value={managerListOption ? managerListOption : OKR?.managerListDTO}
                filterOptions={filterOptions}
                onChange={(value) => handleManagerChange("managerListDTO", value)}
                labelledBy="Select"
              />
            </div>
          </Form.Group> }
        </div>
        <div className={style.save_box_left}>
          <Button type="button"  disabled={true} variant={"primary"} onClick={() => handleSubmit()}>
            <strong>
              {isEditMode ? buttonText.UPDATE : buttonText.CREATE}
            </strong>
          </Button>
          <Button
            type="button"
            variant={"secondary"}
            className="ml-3"
            onClick={handleClear}
          >
            <strong>{buttonText.CLEAR}</strong>
          </Button>
        </div>
      </div>
    </div>
  </>
  );
}
