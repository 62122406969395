import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import style from "./MyObjectives.module.scss";
import * as actions from "store/actions/index";
import { successToast } from "util/general";
import { bandForSelfAssignObjective, noData } from "const/constValue";
import storage from "util/storage";
import {
  DOWNLOAD_OBJECTIVE_SAMPLE,
} from "util/endpoints";
import Spinner from "shared/Spinner/Spinner";
import NoDataFound from "../../NodataFound/NoDataFound";
import ObjectivesList from "./ObjectivesList";
import routePath from "const/routePath";

export default function Myobjectives() {
  const loggedInUser = storage.getUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const employeeIds = storage.getEmployeeIds();
  const { isLoadingObjective, objectives } = useSelector((state) => state.checkIn);

  useEffect(() => {
    if (employeeIds) {
      dispatch({ type: "CLEAR_OBJECTIVE_DETAILS" });
      dispatch(actions.GetUserObjectives(employeeIds));
    }
  }, [dispatch]);

  // let selfAssign = loggedInUser && loggedInUser?.country === "United Kingdom" ? true : !bandForSelfAssignObjective.includes(loggedInUser.band);
  // let selfAssign always true as per new requirement for showing button to all employee
  let selfAssign = true;
  return (
    <div className={style.myobjectives_wrapper}>
      {isLoadingObjective ? (
        <Spinner />
      ) : objectives.length > 0 ? (
        <Container>
          <Row>
            <Col md={7} className="col-12">
              <h1 className="page-title pb-0">Objectives</h1>
              <span className="st-breadcrumb">
                Objectives <span>My Objectives</span>
              </span>
            </Col>
            <Col lg={5} className="text-right">
              <a href={DOWNLOAD_OBJECTIVE_SAMPLE} download>
                <button
                  type="button"
                  onClick={() => successToast("File downloaded Successfully!")}
                  className={[
                    "btn-round save_box_btn btn btn-secondary mr-3",
                    style.create_new_btn,
                  ].join(" ")}
                >
                  <strong>Project | Bench OKR Sample file</strong>
                </button>
              </a>
              {selfAssign && (
                <button
                  onClick={() => history.push(routePath.SELF_OBJECTIVES)}
                  type="button"
                  // added changes for disable OKR feature from Engage
                  disabled={true}
                  className={[
                    "btn-round save_box_btn btn btn-primary",
                    style.create_new_btn,
                  ].join(" ")}
                  data-test-id="create-new-btn"
                >
                  <strong>Add My Objective</strong>
                </button>
              )}
            </Col>
            <Col className="col-12">
              <div
                className={[
                  "custom-tabs custom-tabs-full-width tab-content-dashboard",
                  style.allobjectives_tabs,
                ].join(" ")}
              >
                <ObjectivesList objectives={objectives} isPage="User" />
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row>
            <Col lg={12} className="text-right pb-3">
              {selfAssign && (
                <button
                  onClick={() => history.push(routePath.SELF_OBJECTIVES)}
                  type="button"
                  // added changes for disable OKR feature from Engage
                  disabled={true}
                  className={[
                    "btn-round save_box_btn btn btn-primary",
                    style.create_new_btn,
                  ].join(" ")}
                >
                  <strong>Add My Objective</strong>
                </button>
              )}
            </Col>
            <Col md={12}>
              <div className="box pb-5">
                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                <div className="gap80"></div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}
