import React from "react";
import { DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { IC_CLONE_2, IC_DELETE_2, IC_EDIT_OBJECTIVE, IC_VIEW_DETAILS } from "const/imgCost";

function CustomDropdown({
  customText,
  customPath,
  customSVG,
  editPath,
  handleDelete,
  viewPath,
  deleteText,
  viewText,
  cloneText,
  editText,
  clonePath,
  isEdit,
  editOKRText,
  disabled,
  // added changes for disable OKR feature from Engage
  isAllObjective
}) {
  return (
    <div className="action-dropdown-wrapper">
      <DropdownButton
        alignRight
        title={
          <div className="action-dropdown">
            <span className="dots"></span>
            <span className="dots"></span>
            <span className="dots"></span>
          </div>
        }
        variant="a"
        disabled={disabled}
      >
        {/* // added changes for disable OKR feature from Engage */}
        {customText && !isAllObjective && (
          <Link to={customPath} className="dropdown-item">
            <div className="dropdown_icon">
              <ReactSVG src={`${customSVG}`} alt="" />
            </div>
            <div className="dropdown_item">{customText}</div>
          </Link>
        )}
        {/* // added changes for disable OKR feature from Engage */}
        {editText && !isAllObjective && (
          <Link to={editPath} className="dropdown-item">
            <div className="dropdown_icon">
              <ReactSVG src={`${IC_EDIT_OBJECTIVE}`} alt="" />
            </div>
            <div className="dropdown_item">{editText}</div>
          </Link>
        )}
        {isEdit && (
          <Link to={editPath} className="dropdown-item">
            <div className="dropdown_icon">
              <ReactSVG src={`${IC_EDIT_OBJECTIVE}`} alt="" />
            </div>
            <div className="dropdown_item">{editOKRText}</div>
          </Link>
        )}
        {viewText && (
          <Link to={viewPath} className="dropdown-item">
            <div className="dropdown_icon">
              <ReactSVG src={`${IC_VIEW_DETAILS}`} alt="" />
            </div>
            <div className="dropdown_item">{viewText}</div>
          </Link>
        )}
        {cloneText && (
          <Link to={clonePath} className="dropdown-item">
            <div className="dropdown_icon">
              <ReactSVG src={`${IC_CLONE_2}`} alt="" fill="#000" />
            </div>
            <div className="dropdown_item">{cloneText}</div>
          </Link>
        )}
        {/* // added changes for disable OKR feature from Engage */}
        {deleteText && !isAllObjective && (
          <div className="dropdown_item dropdown-item" onClick={handleDelete}>
            <div className="dropdown_icon">
              <ReactSVG src={`${IC_DELETE_2}`} alt="" />
            </div>
            {deleteText}
          </div>
        )}
      </DropdownButton>
    </div>
  );
}

export default CustomDropdown;

CustomDropdown.defaultProps = {
  handleDelete: () => {},
};
