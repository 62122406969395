import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IC_INFO } from "const/imgCost";
import { dateFormat1, endedDays } from "util/dateUtils";
import CustomDropdown from "shared/Dropdown/CustomDropdown";
import KeyResults from "./KeyResults";
import styles1 from "./TeamsObjectives.module.scss";
import styles2 from "../../HR/EmployeesObjectives/EmployeesObjectives.module.scss";
import storage from "util/storage";

const ObjectiveResults = ({ objective, employeeId, deleteObjective, isPage, employeeBand }) => {
  let style;
  if (isPage === "MANAGER") {
    style = styles1;
  } else {
    style = styles2;
  }
  const [expandKey, setExpandKey] = useState(false);
  const loggedinUser = storage.getUser();
  const toggleKeyExpander = () => {
    setExpandKey(!expandKey);
  };

  let dot = null;
  if (objective.percentage >= 66) dot = <span className={[style.green_status, "status"].join(" ")}></span>;
  else if (objective.percentage > 33) dot = <span className={[style.yellow_status, "status"].join(" ")}></span>;
  else dot = <span className={[style.red_status, "status"].join(" ")}></span>;

  const userSelectedStatus = (status) => {
    if (status === "ORANGE") {
      return <div className="status_color_yellow"></div>;
    } else if (status === "GREEN") {
      return <div className="status_color_green"></div>;
    } else if (status === "RED") {
      return <div className="status_color_red"></div>;
    }
  };

  let status = null;
  if (objective.status) {
    switch (objective.status) {
      case "COMPLETED":
        status = {
          style: style.completed,
          name: "Complete",
          tooltip: "When Objective is 100% complete, and notified by member, and approved by Manager",
        };
        break;
      case "ASSIGNED":
        status = {
          style: style.assigned,
          name: "Assigned",
          tooltip: "When Objective is assigned and Progress is 0%",
        };
        break;
      case "INPROGRESS":
        status = {
          style: style.inprogress,
          name: "In Progress",
          tooltip: "When progress is between 1% to 100%",
        };
        break;
      case "EXPIRED":
        status = {
          style: style.notassigned,
          name: "Expired",
          tooltip: "When the date range provided for completion of the Objective has elapsed.",
        };
        break;
      case "PENDING_APPROVAL":
        status = {
          style: style.pending_approval,
          name: "Pending Approval",
          tooltip: "When Objective is 100% complete, and notified by member, but not approved by Manager",
        };
        break;
      case "INVALID":
        status = {
          style: style.pending_approval,
          name: "Invalid",
          tooltip: "When Objective is marked as Invalid by Manager",
        };
        break;
      case "DRAFTED":
        status = {
          style: style.drafted,
          name: "Drafted",
          tooltip: "When Manager A has created Self Objective but his/her Manager B has not approved that Objective.",
        };
        break;
      case "STRIKED_OUT":
        status = {
          style: style.strikeout,
          name: "Striked Out",
          tooltip: "When an Objective is no longer valid and it has been cut short by the Manager",
        };
        break;
      default:
        return null;
    }
  }
  
  return (
    <>
      <li className={[style.keyresult_wrapper, expandKey && style.open_keyresult].join(" ")}>
        <div className={[style.keyresult_container, "text-break"].join(" ")}>
          <div className={style.keyresult_titlewrapper}>
            <h2>
              {objective.progressStatus && userSelectedStatus(objective.progressStatus)}

              {isPage === "MANAGER" ? (
                <Link
                  to={`/objectives/all-objectives/assigned-objective/${objective.userObjectiveId}?empId=${employeeId}&pgId=${objective.parentGroupId}&sgId=${objective.subGroupId}`}
                >
                  {objective.title}
                </Link>
              ) : (
                objective.title
              )}
            </h2>
            <h3>
              {objective?.keyPoints?.length} Key Results Aligned
              <span>
                {dateFormat1(objective.startDate)} - {dateFormat1(objective.endDate)}
              </span>
            </h3>
            <h3>
              {objective.managerData && objective.managerData.empName}
              <span>Weightage :{objective?.weightage ? objective?.weightage + "%": "-" }</span>
            </h3>
          </div>
          <div className={style.keyresults} onClick={() => toggleKeyExpander()}>
            <span className={style.keyres}> 
              Key Results <i className={style.arrow}></i>
            </span>
          </div>
          <div className={style.obj_status}>
            {isPage === "MANAGER" && (
              <>
                <div className={status.style}>{status.name}</div>
                <div className={[style.status_tooltip, "custom-tooltip"].join(" ")}>
                  <div className="box-info">
                    <span>
                      <img className="svg" src={IC_INFO} alt="box-info" />
                    </span>
                  </div>
                  <div className="info-attach-file">
                    <p>{status.tooltip}</p>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={style.status_in_days}>
            <span className="progress_kr">
              {dot}
              {objective.percentage}%
              {objective.status === "COMPLETED" ? (
                <span className="days">Completed</span>
              ) : (
                <span className="days">{endedDays(objective.endDate)}</span>
              )}
            </span>
          </div>
          {isPage === "MANAGER" &&
            !objective.isNotify &&
            objective.status !== "COMPLETED" &&
            objective.status !== "DRAFTED" && (
              <div className={style.action_dd_kr}>
                <CustomDropdown
                  editOKRText="Edit Objective"
                  // added changes for disable OKR feature from Engage
                  disabled={true}
                  isEdit ={objective?.managerData?.employeeId + objective?.managerData?.parentGroupId + objective?.managerData?.subGroupId === loggedinUser?.employeeId + loggedinUser?.parentGroupId + loggedinUser?.subGroupId}
                  deleteText="Delete Objective"
                  editPath={{
                    pathname: `/objectives/edit-objective/${objective.userObjectiveId}`,
                    search: employeeId
                      ? `?empId=${employeeId}&pgId=${objective.parentGroupId}&sgId=${objective.subGroupId}&band=${employeeBand}`
                      : "",
                  }}
                  handleDelete={() => {
                    deleteObjective(
                      objective.userObjectiveId,
                      employeeId,
                      objective.parentGroupId,
                      objective.subGroupId
                    );
                  }}
                />
              </div>
            )}
        </div>
      </li>
      {expandKey && objective.keyPoints && (
        <li className={style.keyresult_dropdown}>
          <ul className="common_list_items">
            {objective.keyPoints.map((keyPoint, index) => (
              <KeyResults keyPoint={keyPoint} key={"KEYPOINT_" + index} />
            ))}
          </ul>
        </li>
      )}
    </>
  );
};
export default ObjectiveResults;
