import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Button,
  Tabs,
  Tab,
  Form,
  Table,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { ReactSVG } from "react-svg";
import { ExcelRenderer } from "react-excel-renderer";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import style from "./AllObjectives.module.scss";
import * as actions from "store/actions/index";
import { IC_INPUT_SEARCH, IC_INFO } from "const/imgCost";
import {
  DELETE_OBJECTIVE,
  DOWNLOAD_OBJECTIVE_SAMPLE,
  FILE_UPLOAD,
} from "util/endpoints";
import {
  filterArray,
  scrollToTop,
  exportTeamObjectives,
  successToast,
} from "util/general";
import storage from "util/storage";
import http from "util/http";
import Spinner from "shared/Spinner/Spinner";
import SlideToast from "components/Toast/Toast";
import Pagination from "../../Pagination/Pagination";
import UniversityProgramRows from "./UniversityProgramRows";
import MyCreatedRows from "./MyCreatedRows";
import NoDataFound from "../../NodataFound/NoDataFound";
import ConfirmationPopup from "../ConfirmationPopup";
import ComfirmUploadPopup from "./comfirmUploadPopup";
import DetailErrorInfoPopup from "./detailErrorInfoPopup";
import { noData } from "const/constValue";
import SubNavigation from "components/Header/SubNavigation/SubNavigation";
import { useQuery } from "util/utils";
import routePath from "const/routePath";
import OKRDetailsPopup from "../TeamsObjectives/OKRDetailsPopup/OKRDetailsPopup";
import InfoBannerContact from "components/InfoContactBanner/InfoBannerInfo";
import { okrRedirectionText, okrRedirectionLink } from "const/constValue";

const TabEventKeys = {
  UNIVERSITY_PROGRAM: "UniversityProgram",
  CREATED_OBJECTIVE: "MyCreatedObjective",
  SERVICE_LINE_OBJECTIVE: "ServiceLineObjective",
};
const AllObjectives = (props) => {
  let tab = props.location.query && props.location.query.tab;

  const dispatch = useDispatch();
  const history = useHistory();
  const loggedInUser = storage.getUser();
  const employeeId = useQuery().get("empId");
  const parentGroupId = useQuery().get("pgId");
  const subGroupId = useQuery().get("sgId");
  // change default key for satisfy the requirement of EN-7973
  // const [defaultKey, setDefaultKey] = useState(tab ? tab : TabEventKeys.UNIVERSITY_PROGRAM);
  const [defaultKey, setDefaultKey] = useState(
    tab ? tab : TabEventKeys.CREATED_OBJECTIVE
  );
  const {
    universityObjectiveResponse,
    createObjectiveResponse,
    allObjectiveLoader,
    serviceLineObjectives,
    predefinedObjective,
    teamObjectiveData,
  } = useSelector((state) => state.objectives);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [serviceLineItems, setServiceLineItems] = useState([]);
  const [createPageOfItems, setCreatePageOfItems] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [serviceLine, setServiceLine] = useState([]);
  const [createdData, setCreatedData] = useState([]);
  const [searchFilter, setSearchFilter] = useState(null);
  const [filteredData, setFilteredData] = useState(universityObjectiveResponse);
  const [serviceLineData, setServiceLineData] = useState(serviceLineObjectives);
  const [filteredCreatedData, setFilteredCreatedData] = useState(
    createObjectiveResponse
  );
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [currentObj, setCurrentObj] = useState("");
  const [confirmUploadPopup, setConfirmUploadPopup] = useState(false);
  const [file, setFile] = useState(null);
  const [objectivesData, setObjectiveData] = useState([]);
  const [errorFlag, setErrorFlag] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [isColumnError, setIsColumnError] = useState(false);
  const [uploadFileLoader, setUploadLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [objectiveTitle, setObjectiveTitle] = useState();
  const [popupTeam, setPopupTeam] = useState();
  const [errorList, setErrorList] = useState([]);
  let inputElement = React.createRef();
  const headerText = "Sure you want to delete this objective?";
  const subText =
    "Since the objective has weightage attached to it, we advise altering it. You cannot undo a deletion once it has been made.";
  const size = useSelector((state) => state.objectives.size);
  const pageSize = useSelector((state) => state.myTeam.pageSize);

  const onChangePage = (pageOfItems) => setPageOfItems(pageOfItems);
  const onChangeServicePage = (items) => setServiceLineItems(items);
  const onChangeObjectivePage = (createPageOfItems) => setCreatePageOfItems(createPageOfItems);
  let className = "form-control-rounded form-search-item";
  if (searchFilter && searchFilter.length > 0) {
    className = "form-control-rounded form-search-item search-character";
  }
  useEffect(() => {
    scrollToTop(500);
    // dispatch({type: "CLEAR_MY_TEAM_DATA"})
    // dispatch(actions.AllObjective("UNIVERSITY")); comment this line code for satisfy the requirement of EN-7973
    dispatch(actions.AllObjective("MANAGER"));
    // dispatch(actions.AllObjective("SERVICE_LINE"));
    setSearchFilter("");
    dispatch(actions.GetTeamObjectiveData());
    dispatch(
      actions.getPrdefinedObjective(
        loggedInUser?.podInformation,
        loggedInUser?.country
      )
    );
  }, [dispatch]);

  useEffect(() => {
    setObjectiveTitle(predefinedObjective?.map((a) => a.label.trim()));
  }, [predefinedObjective]);

  useEffect(() => {
    setSearchFilter("");
    setFilteredData(universityObjectiveResponse);
  }, [universityObjectiveResponse, defaultKey]);

  useEffect(() => {
    setSearchFilter("");
    let sortedData = createObjectiveResponse.sort(
      (a, b) => moment(b.createdDate) - moment(a.createdDate)
    );
    setFilteredCreatedData(sortedData);
  }, [createObjectiveResponse, defaultKey]);

  useEffect(() => {
    if (createObjectiveResponse.length > 0) {
      if (props.location.state) {
        switch (props.location.state.type) {
          case "TEAM_OBJECTIVE":
            setDefaultKey(TabEventKeys.CREATED_OBJECTIVE);
            document.getElementById("team-objective").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
            break;
          case "UNIVERSITY_OBJECTIVE":
            setDefaultKey(TabEventKeys.UNIVERSITY_PROGRAM);
            break;
          case "SERVICE_LINE_OBJECTIVE":
            setDefaultKey(TabEventKeys.SERVICE_LINE_OBJECTIVE);
            break;
          default:
            break;
        }
        props.location.state.type = "";
      }
    }
  }, [props.location.state, createObjectiveResponse]);

  useEffect(() => {
    const filteredTeamData = teamObjectiveData?.filter((value) => value.totalWeightageOfManager < 100);
    setPopupTeam(filteredTeamData);
  }, [teamObjectiveData]);

  const handleCreateClick = () => {
    history.push(routePath.CREATE_OBJECTIVES);
  };

  const resetData = () => {
    let filteredData = null,
      filterCreatedData = null;
    const filters = {
      title: searchFilter ? searchFilter : "",
    };
    if (defaultKey === TabEventKeys.UNIVERSITY_PROGRAM) {
      filteredData = filterArray(universityObjectiveResponse, filters);
      setFilteredData(filteredData);
    } else if (defaultKey === TabEventKeys.SERVICE_LINE_OBJECTIVE) {
      filterCreatedData = filterArray(serviceLineObjectives, filters);
      setServiceLineData(filterCreatedData);
    } else {
      filterCreatedData = filterArray(createObjectiveResponse, filters);
      setFilteredCreatedData(filterCreatedData);
    }
    setSearchFilter(searchFilter);
  };

  const search = (e) => {
    setSearchFilter(e.target.value);
    let filteredData = null,
      filterCreatedData = null;

    //Tag search filter
    if (e.target.value.includes("#")) {
      let arr = e.target.value.substring(1);
      const filters = {
        tags: arr,
      };
      let array = createObjectiveResponse
        .filter((value) => value.tags && value.tags.length > 0)
        .map((value) => {
          return {
            keyPoints: value.keyPoints,
            title: value.title,
            tags: value.tags.map((value) => value.name).join(", "),
            objectiveId: value.objectiveId,
          };
        });
      if (defaultKey === TabEventKeys.CREATED_OBJECTIVE) {
        filterCreatedData = filterArray(array, filters);
        setFilteredCreatedData(filterCreatedData);
      }
    }
    //Tag search filter
    else {
      const filters = {
        title: e.target.value,
      };
      if (defaultKey === TabEventKeys.UNIVERSITY_PROGRAM) {
        filteredData = filterArray(universityObjectiveResponse, filters);
        setFilteredData(filteredData);
      } else if (defaultKey === TabEventKeys.SERVICE_LINE_OBJECTIVE) {
        filterCreatedData = filterArray(serviceLineObjectives, filters);
        setServiceLineData(filterCreatedData);
      } else {
        filterCreatedData = filterArray(createObjectiveResponse, filters);
        setFilteredCreatedData(filterCreatedData);
      }
    }
  };

  const resetSearch = () => {
    let filteredData = null,
      filterCreatedData = null;
    const filters = {
      description: "",
    };

    if (defaultKey === TabEventKeys.UNIVERSITY_PROGRAM) {
      filteredData = filterArray(universityObjectiveResponse, filters);
      setFilteredData(filteredData);
    } else if (defaultKey === TabEventKeys.SERVICE_LINE_OBJECTIVE) {
      filterCreatedData = filterArray(serviceLineObjectives, filters);
      setServiceLineData(filterCreatedData);
    } else {
      filterCreatedData = filterArray(createObjectiveResponse, filters);
      setFilteredCreatedData(filterCreatedData);
    }
    setSearchFilter("");
  };

  useEffect(() => {
    filteredData.length > 0
      ? setTeamData(filteredData)
      : searchFilter
        ? setTeamData(filteredData)
        : setTeamData(universityObjectiveResponse);
  }, [filteredData, searchFilter, universityObjectiveResponse]);

  useEffect(() => {
    serviceLineData.length > 0
      ? setServiceLine(serviceLineData)
      : searchFilter
        ? setServiceLine(serviceLineData)
        : setServiceLine(serviceLineObjectives);
  }, [serviceLineData, searchFilter, serviceLineObjectives]);

  useEffect(() => {
    filteredCreatedData.length > 0
      ? setCreatedData(filteredCreatedData)
      : searchFilter
        ? setCreatedData(filteredCreatedData)
        : setCreatedData(createObjectiveResponse);
  }, [filteredCreatedData, searchFilter, createObjectiveResponse]);

  const deleteObjective = () => {
    setDeleteLoader(true);
    setConfirmPopup(false);
    http
      .delete(DELETE_OBJECTIVE, { data: { objectiveId: currentObj } }, {})
      .then((response) => response.data)
      .then(() => {
        setCurrentObj("");
        dispatch(actions.AllObjective("MANAGER"));
        setDeleteLoader(false);
        successToast("Deleted succesfully");

        history.replace(routePath.ALL_OBJECTIVES);
      })
      .catch(() => {
        setDeleteLoader(false);
        successToast("Somthing wrong !");
      });
  };

  const uploadFileAPI = (files) => {
    inputElement.value = "";
    ExcelRenderer(files, (err, resp) => {
      if (err) {
        console.warn(err);
      } else {
        let obj = {
          cols: resp.cols || [],
          rows: resp.rows || [],
        };
        let index = {
          count: 0,
          objectIndex: [],
        };
        if (
          obj.rows[0][0] === "objective_name" &&
          obj.rows[0][1] === "objective_weightage" &&
          obj.rows[0][2] === "emp_band"
        ) {
          setIsColumnError(false);
          setErrorList([]);
          let objectives = [];
          obj.rows.map((temp, i) => {
            if (i !== 0 && temp.length !== 0) {
              let temp0 =
                typeof temp[0] === "string" ? temp[0]?.trim() : temp[0];
              let temp1 =
                typeof temp[1] === "string" ? temp[1]?.trim() : temp[1];
              let temp2 =
                typeof temp[2] === "string" ? temp[2]?.trim() : temp[2];

              if (
                (temp0 && temp0 !== "") ||
                (temp1 && temp1 !== "") ||
                (temp2 && temp2 !== "")
              ) {
                index.count++;
                index.objectIndex.push(i);
                let _obj = {
                  objectiveName:
                    temp[0] === ""
                      ? null
                      : typeof temp[0] === "string"
                        ? temp[0]?.trim()
                        : temp[0],
                  weightage: temp[1] === "" ? null : temp[1],
                  band: temp[2] || null,
                  objectiveDescription: temp[3],
                  tags:
                    temp[9] === undefined || temp[9] === "" ? null : temp[9],
                  keyAreas: [],
                };
                objectives.push(_obj);
              }
            }
            return temp;
          });
          for (let i = 0; i < index.objectIndex.length; i++) {
            let start = index.objectIndex[i];
            let end =
              index.objectIndex[i + 1] === undefined
                ? obj.rows.length
                : index.objectIndex[i + 1];

            for (let j = start; j < end; j++) {
              if (
                obj.rows[j][3] !== undefined ||
                obj.rows[j][4] !== undefined ||
                obj.rows[j][5]
              ) {
                let temp = {
                  description:
                    obj.rows[j][4] === undefined ||
                      typeof obj.rows[j][4] === "string"
                      ? obj.rows[j][4].trim()
                      : obj.rows[j][4] === ""
                        ? null
                        : obj.rows[j][4],
                  keyAreaWeightage: obj.rows[j][5],
                  unit: obj.rows[j][6],
                  startValue:
                    obj.rows[j][7] === undefined || obj.rows[j][7] === ""
                      ? null
                      : obj.rows[j][7],
                  endValue:
                    obj.rows[j][8] === undefined || obj.rows[j][8] === ""
                      ? null
                      : obj.rows[j][8],
                  stretch:
                    obj.rows[j][10] === undefined ? "No" : obj.rows[j][10],
                };
                objectives[i].keyAreas.push(temp);
              }
            }
          }
          let weightageTotalFlag = false;
          let bandError = false;
          let _tempList = [];
          objectives.forEach((singleObj) => {
            let total = 0;
            singleObj.keyAreas.forEach((o) => {
              total += o.keyAreaWeightage;
              if (
                !o.description ||
                o.description === undefined ||
                o.keyAreaWeightage === undefined ||
                o.unit === undefined ||
                typeof o.keyAreaWeightage === "string"
              ) {
                weightageTotalFlag = true;
                let list = [..._tempList];
                let obj =
                  "key_areas cannot be empty, key_area_weightage must be numeric and unit fields is compulsary.";
                if (!list.includes(obj)) {
                  list.push(obj);
                }
                _tempList = [...list];
              } else {
                if (o.description && o.description.length > 512) {
                  weightageTotalFlag = true;
                  let list = [..._tempList];
                  let obj =
                    "Please make sure that key_areas length is not more than 512 characters.";
                  if (!list.includes(obj)) {
                    list.push(obj);
                  }
                  _tempList = [...list];
                }
                if (
                  o.keyAreaWeightage.toString().length > 4 ||
                  o.keyAreaWeightage <= 0 ||
                  o.keyAreaWeightage > 100
                ) {
                  weightageTotalFlag = true;
                  let list = [..._tempList];
                  let obj =
                    "Please make sure that key_area_weightage is greater than or equal to 0 and less than or equal to 100";
                  if (!list.includes(obj)) {
                    list.push(obj);
                  }
                  _tempList = [...list];
                }
              }
              if (
                o.unit !== "%" &&
                o.unit !== "up" &&
                o.unit !== "down" &&
                o.unit !== "between" &&
                o.unit !== "?"
              ) {
                weightageTotalFlag = true;
                let list = [..._tempList];
                let obj =
                  "Please ensure unit column has value 'up', 'down', 'between', ?, %";
                if (!list.includes(obj)) {
                  list.push(obj);
                }
                _tempList = [...list];
              }
              if (
                o.unit === "%" ||
                o.unit === "up" ||
                o.unit === "down" ||
                o.unit === "between"
              ) {
                if (
                  o.startValue === null ||
                  o.endValue === null ||
                  typeof o.startValue === "string" ||
                  typeof o.endValue === "string" ||
                  o.startValue < 0 ||
                  o.endValue < 0
                ) {
                  if (o.startValue === "0" || o.endValue === "0") {
                    weightageTotalFlag = false;
                  } else {
                    weightageTotalFlag = true;
                  }
                  let list = [..._tempList];
                  let obj =
                    "Please ensure that base_value and target_value cannot be string, empty, less than 0.";
                  if (!list.includes(obj)) {
                    list.push(obj);
                  }
                  _tempList = [...list];
                } else {
                  let start = o.startValue.toString();
                  let end = o.endValue.toString();
                  if (o.unit === "%") {
                    let length = start.indexOf(".") === -1 ? 3 : 5;
                    if (
                      start.length > length ||
                      end.length > length ||
                      o.startValue === o.endValue
                    ) {
                      weightageTotalFlag = true;
                      let list = [..._tempList];
                      let obj =
                        "Please ensure that base_value and target_value are not the same. If unit is % then provide only upto 3 digits without decimal and 5 digits with decimal.";
                      if (!list.includes(obj)) {
                        list.push(obj);
                      }
                      _tempList = [...list];
                    }
                  } else if (o.unit === "up") {
                    if (
                      start.length > 10 ||
                      end.length > 10 ||
                      o.startValue === o.endValue ||
                      o.startValue > o.endValue
                    ) {
                      weightageTotalFlag = true;
                      let list = [..._tempList];
                      let obj =
                        "Please ensure that base_value and target_value are not the same. If unit is 'up' then base_value and target_value must be less than 10 digits and base_value must be less than target_value.";
                      if (!list.includes(obj)) {
                        list.push(obj);
                      }
                      _tempList = [...list];
                    }
                  } else if (o.unit === "down") {
                    if (
                      start.length > 10 ||
                      end.length > 10 ||
                      o.startValue === o.endValue ||
                      o.startValue < o.endValue
                    ) {
                      weightageTotalFlag = true;
                      let list = [..._tempList];
                      let obj =
                        "Please ensure that base_value and target_value are not the same. If unit is 'down' then base_value and target_value must be less than 10 digits and base_value must be greater than target_value.";
                      if (!list.includes(obj)) {
                        list.push(obj);
                      }
                      _tempList = [...list];
                    }
                  } else if (o.unit === "between") {
                    if (
                      start.length > 10 ||
                      end.length > 10 ||
                      o.startValue === o.endValue
                    ) {
                      weightageTotalFlag = true;
                      let list = [..._tempList];
                      let obj =
                        "Please ensure that base_value and target_value are not the same. If unit is 'between' then base_value and target_value must be less than 10 digits.";
                      if (!list.includes(obj)) {
                        list.push(obj);
                      }
                      _tempList = [...list];
                    }
                  }
                }
              }
              if (
                o.stretch === "Yes" ||
                o.stretch === "No" ||
                o.isStretch === "yes" ||
                o.stretch === "no"
              ) {
                if (o.stretch === "no" || o.stretch === "No") {
                  o.stretch = false;
                }
                if (o.stretch === "Yes" || o.stretch === "yes") {
                  o.stretch = true;
                }
              } else {
                weightageTotalFlag = true;
                let list = [..._tempList];
                let obj =
                  "You have added wrong value in stretch_keyresult column. Please provide values either 'Yes' or 'No'.";
                if (!list.includes(obj)) {
                  list.push(obj);
                }
                _tempList = [...list];
              }
            });
            let check = predefinedObjective.filter(
              (o) => o.label.trim() === singleObj.objectiveName.trim()
            );
            if (
              singleObj.objectiveName === undefined ||
              singleObj.objectiveName === null
            ) {
              weightageTotalFlag = true;
              let list = [..._tempList];
              let obj =
                "Please ensure that objective_name column is not empty.";
              if (!list.includes(obj)) {
                list.push(obj);
              }
              _tempList = [...list];
            } else if (!objectiveTitle.includes(singleObj.objectiveName)) {
              weightageTotalFlag = true;
              let list = [..._tempList];
              let obj =
                "Please ensure that objective_name should exact same as given.";
              if (!list.includes(obj)) {
                list.push(obj);
              }
              _tempList = [...list];
            } else if (
              singleObj.weightage === undefined ||
              singleObj.weightage === null
            ) {
              weightageTotalFlag = true;
              let list = [..._tempList];
              let obj =
                "Please ensure that objective_weightage column is not empty.";
              if (!list.includes(obj)) {
                list.push(obj);
              }
              _tempList = [...list];
            } else if (
              singleObj.weightage === undefined ||
              typeof singleObj.weightage === "string"
            ) {
              weightageTotalFlag = true;
              let list = [..._tempList];
              let obj = "weightage must be numeric is compulsary.";
              if (!list.includes(obj)) {
                list.push(obj);
              }
              _tempList = [...list];
            } else if (
              singleObj.weightage.toString().length > 4 ||
              singleObj.weightage <= 0 ||
              singleObj.weightage > 100
            ) {
              weightageTotalFlag = true;
              let list = [..._tempList];
              let obj =
                "Please make sure that objective_weightage is greater than  0 and less than or equal to 100.";
              if (!list.includes(obj)) {
                list.push(obj);
              }
              _tempList = [...list];
            } else if (check) {
              if (
                check.filter((o) => o.weightage === singleObj.weightage)
                  .length === 0
              ) {
                weightageTotalFlag = true;
                let list = [..._tempList];
                let obj =
                  "Please make sure that objective_weightage should exact match with template.";
                if (!list.includes(obj)) {
                  list.push(obj);
                }
                _tempList = [...list];
              }
            } else {
              if (
                singleObj.objectiveName.length > 200 ||
                (singleObj.objectiveDescription &&
                  singleObj.objectiveDescription.length > 512)
              ) {
                weightageTotalFlag = true;
                let list = [..._tempList];
                let chars = 0;
                let letter = "";
                if (singleObj.objectiveName.length > 200) {
                  chars = 200;
                  letter = "objective_name";
                }
                if (singleObj.objectiveDescription.length > 512) {
                  chars = 512;
                  letter = "objective_description";
                }
                let obj =
                  "Please ensure that " +
                  letter +
                  " does not excceed " +
                  chars +
                  " characters.";
                if (!list.includes(obj)) {
                  list.push(obj);
                }
                _tempList = [...list];
              }
            }
            if (!singleObj?.band) {
              let list = [..._tempList];
              let obj = "Please ensure that emp_band column is not empty.";
              if (!list.includes(obj)) {
                list.push(obj);
              }
              _tempList = [...list];
              bandError = true;
            } else if (check[0]?.band != singleObj?.band) {
              let list = [..._tempList];
              let obj =
                "Please make sure that emp_band should exact match with template.";
              if (!list.includes(obj)) {
                list.push(obj);
              }
              _tempList = [...list];
              bandError = true;
            }
            if (
              Array.isArray(singleObj.tags) &&
              findDuplicates(singleObj.tags).length > 0
            ) {
              weightageTotalFlag = true;
              let list = [..._tempList];
              let obj = "Please ensure that there are no duplicate tags added.";
              if (!list.includes(obj)) {
                list.push(obj);
              }
              _tempList = [...list];
            }
            if (total !== 100) {
              weightageTotalFlag = true;
              let list = [..._tempList];
              let obj =
                "Please ensure key_area_weightage for an objective is equal to 100.";
              if (!list.includes(obj)) {
                list.push(obj);
              }
              _tempList = [...list];
            }
          });
          setErrorList(_tempList);
          setObjectiveData(objectives);
          if (weightageTotalFlag) {
            setErrorFlag(weightageTotalFlag);
          } else if (bandError) {
            setErrorFlag(bandError);
          } else {
            setErrorFlag(false);
          }
          setConfirmUploadPopup(true);
        } else {
          setErrorList([]);
          setIsColumnError(true);
          setErrorFlag(true);
          setConfirmUploadPopup(true);
        }
      }
    });
  };

  const findDuplicates = (arr) => {
    if (arr === undefined || arr === null) return [];
    let sorted_arr = arr.split(",").slice().sort();
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] === sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    return results;
  };

  const getObjective = (objectiveId) => {
    setConfirmPopup(true);
    setCurrentObj(objectiveId);
  };

  const uploadObjectiveFile = (body) => {
    setUploadLoader(true);
    http
      .post(FILE_UPLOAD, {}, body)
      .then((response) => response.data)
      .then((response) => {
        setUploadLoader(false);
        if (response.status === "FAILURE") {
          toast.success(
            <SlideToast
              title="Opps!"
              message={"Something went wrong! Please try again!"}
              error={true}
            />,
            {
              toastId: "KEY_WEIGHT_TOTAL_1",
            }
          );
        } else {
          toast.success(
            <SlideToast
              title="Awesome!"
              message={"File uploaded successfully"}
              error={false}
            />,
            {
              toastId: "KEY_WEIGHT_TOTAL_2",
            }
          );
          dispatch(actions.AllObjective("MANAGER"));
        }
      })
      .catch(() => {
        setUploadLoader(false);
        toast.success(
          <SlideToast
            title="Opps!"
            message={"Something went wrong! Please try again!"}
            error={true}
          />,
          {
            toastId: "KEY_WEIGHT_TOTAL_1",
          }
        );
      });
  };

  let buttonClass = "";
  if (defaultKey === TabEventKeys.UNIVERSITY_PROGRAM) {
    buttonClass = style.hide_button;
  } else {
    buttonClass = style.show_button;
  }

  return (
    <div className={style.allobjectives_wrapper}>
      <SubNavigation type="Objectives" page="Objectives"/>
      <InfoBannerContact text={okrRedirectionText} linkUrl={okrRedirectionLink} page="Objectives" />
      {uploadFileLoader || (deleteLoader && <Spinner />)}
      {allObjectiveLoader ? (
        <Spinner />
      ) : (
        <Container>
          <Row>
            <Col md={3} lg={4} sm={6} className="col-12">
              <h1 className="page-title pb-0">Objectives</h1>
              <span className="st-breadcrumb">
                Objectives
                <span>All Objectives</span>
              </span>
            </Col>
            <Col lg={8} md={9} sm={6} className="text-right col-12">
              <a
                href={DOWNLOAD_OBJECTIVE_SAMPLE}
                download
                className={buttonClass}
              >
                <button
                  type="button"
                  onClick={() => successToast("File downloaded Successfully!")}
                  className={[
                    "btn-round save_box_btn btn btn-secondary mr-3",
                    style.create_new_btn,
                    buttonClass,
                  ].join(" ")}
                >
                  <strong>Project | Bench OKR Sample file</strong>
                </button>
              </a>
              <input
                id="fileid"
                type="file"
                accept=".xlsx"
                hidden
                ref={(input) => (inputElement = input)}
                onChange={() => {
                  setFile(inputElement.files[0]);
                  uploadFileAPI(inputElement.files[0]);
                }}
              />
              <button
                onClick={() => {
                  inputElement.click();
                }}
                type="button"
                className={[
                  "btn-round save_box_btn btn btn-secondary mr-3",
                  style.create_new_btn,
                  buttonClass,
                ].join(" ")}
                // added changes for disable OKR feature from Engage
                disabled={true}
              >
                <strong>Upload File</strong>
              </button>
              <button
                onClick={() => handleCreateClick()}
                type="button"
                className={[
                  "btn-round save_box_btn btn btn-primary",
                  style.create_new_btn,
                ].join(" ")}
                // added changes for disable OKR feature from Engage
                disabled={true}
              >
                <strong>Create New</strong>
              </button>
              <div className="custom-tooltip custom-obj-tooltip">
                <div
                  className={["box-info", buttonClass].join(" ")}
                  onClick={() => {
                    setErrorPopup(true);
                  }}
                >
                  <span>
                    <ReactSVG src={IC_INFO} />
                  </span>
                </div>
              </div>
            </Col>
            <Col className="col-12">
              <div
                className={[
                  "custom-tabs custom-tabs-full-width tab-content-dashboard",
                  style.allobjectives_tabs,
                ].join(" ")}
              >
                <Tabs
                  activeKey={defaultKey}
                  onSelect={(key) => {
                    setDefaultKey(key);
                  }}
                  id="all-objective"
                >
                  {/* note: comment this tab code for satisfy the requirement of EN-7973 */}
                  {/* <Tab eventKey={TabEventKeys.UNIVERSITY_PROGRAM} title="University Programs">
                    <div className={style.tab_container_wrapper}>
                      <div className={style.tab_heading}>
                        <Row>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className={style.tab_title}>
                              <h2>Programs Available</h2>
                              <h3>You can select 1 objective at time</h3>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className={style.search_bar}>
                        <Form className={style.allobjective_searchbar}>
                          <Form.Group className="form-postfix mb-0 w-100">
                            <Form.Control
                              size="sm"
                              type="text"
                              placeholder="E.g. Java, React JS, UX"
                              name="searchBox"
                              className={className}
                              onChange={search}
                              value={searchFilter ? searchFilter : ""}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") e.preventDefault();
                              }}
                            />
                            <Button className="close-button" type="reset" onClick={resetSearch}>
                              <span className="close-icon"></span>
                            </Button>
                            <div className="form-postfix-icon">
                              <img src={IC_INPUT_SEARCH} title="" alt="" />
                            </div>
                          </Form.Group>
                        </Form>
                      </div>
                      <div className={style.allobjective_table}>
                        <div className="box-light-gradient"></div>
                        <Col xs={12}>
                          <div className="custom-table table-theme-1 pt40">
                            {pageOfItems && pageOfItems.length > 0 ? (
                              <Table className={style.tab_table_responsive}>
                                <thead>
                                  <tr>
                                    <td className={style.name}>Name</td>
                                    <td className={style.key_result}>Key Results</td>
                                    <td className={style.duration}>Duration</td>
                                    <td className={style.action}>Action</td>
                                  </tr>
                                </thead>
                                {pageOfItems.map((teamRow, index) => (
                                  <UniversityProgramRows
                                    teamRow={teamRow}
                                    key={"TEAM_ROW" + index}
                                    time={`${teamRow.hours} Hours ${teamRow.minutes} Mins`}
                                    titlePathName={{
                                      pathname: `${routePath.UNIVERSITY_OBJECTIVE}/${teamRow.objectiveId}`,
                                      state: { universityObjectiveByManager: true },
                                    }}
                                    assignLink={{
                                      pathname: `${routePath.ASSIGN_OBJECTIVE}/${teamRow.objectiveId}`,
                                      search: employeeId
                                        ? `?empId=${employeeId}&pgId=${parentGroupId}&sgId=${subGroupId}`
                                        : "",
                                      state: { universityObjective: true },
                                    }}
                                    eventKey={defaultKey}
                                  />
                                ))}
                              </Table>
                            ) : (
                              <div>
                                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
                                <div className="gap120"></div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </div>
                    </div>

                    <Pagination
                      pageSize={pageSize}
                      items={teamData}
                      resetData={resetData}
                      onChangePage={onChangePage}
                    />
                  </Tab> */}
                  {false && (
                    <Tab
                      eventKey={TabEventKeys.SERVICE_LINE_OBJECTIVE}
                      title="Service Line Objective"
                    >
                      <div className={style.tab_container_wrapper}>
                        <div className={style.tab_heading}>
                          <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                              <div className={style.tab_title}>
                                <h2>Service Line Available Objective</h2>
                                <h3>You can select 1 objective at time</h3>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className={style.search_bar}>
                          <Form className={style.allobjective_searchbar}>
                            <Form.Group className="form-postfix mb-0 w-100">
                              <Form.Control
                                // size="sm"
                                type="text"
                                placeholder="E.g. Java, React JS, UX"
                                name="searchBox"
                                className={className}
                                onChange={search}
                                value={searchFilter ? searchFilter : ""}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") e.preventDefault();
                                }}
                              />
                              <Button
                                className="close-button"
                                type="reset"
                                onClick={resetSearch}
                              >
                                <span className="close-icon"></span>
                              </Button>
                              <div className="form-postfix-icon">
                                <img src={IC_INPUT_SEARCH} title="" alt="" />
                              </div>
                            </Form.Group>
                          </Form>
                        </div>
                        <div className={style.allobjective_table}>
                          <div className="box-light-gradient"></div>
                          <Col xs={12}>
                            <div className="custom-table table-theme-1 pt40">
                              {serviceLineItems &&
                                serviceLineItems.length > 0 ? (
                                <Table className={style.tab_table_responsive}>
                                  <thead>
                                    <tr>
                                      <td className={style.name}>Name</td>
                                      <td className={style.key_result}>
                                        Key Results
                                      </td>
                                      <td className={style.duration}>
                                        Key Value
                                      </td>
                                      <td className={style.action}>Action</td>
                                    </tr>
                                  </thead>
                                  {serviceLineItems.map((teamRow, index) => (
                                    <UniversityProgramRows
                                      teamRow={teamRow}
                                      key={"TEAM_ROW" + index}
                                      eventKey={defaultKey}
                                      titlePathName={{
                                        pathname: `/objectives/all-objectives/created-objective/${teamRow.objectiveId}`,
                                        state: { from: "MyCreatedObjective" },
                                        search: "?type=service_line",
                                      }}
                                      assignLink={{
                                        pathname: `${resetData.ASSIGN_OBJECTIVE}/${teamRow.objectiveId}`,
                                        search: employeeId
                                          ? `?empId=${employeeId}&pgId=${parentGroupId}&sgId=${subGroupId}`
                                          : "",
                                        state: { teamObjective: true },
                                      }}
                                    />
                                  ))}
                                </Table>
                              ) : (
                                <div>
                                  <NoDataFound
                                    description={noData.WE_DONT_HAVE_THE_DATA}
                                  />
                                  <div className="gap120"></div>
                                </div>
                              )}
                            </div>
                          </Col>
                        </div>
                      </div>

                      <Pagination
                        pageSize={pageSize}
                        items={serviceLine}
                        resetData={resetData}
                        onChangePage={onChangeServicePage}
                      />
                    </Tab>
                  )}
                  {/* currently this tab showing without title for satisfy the requirement of EN-7973  */}
                  {/* <Tab eventKey={TabEventKeys.CREATED_OBJECTIVE} title="Team Objectives"> */}
                  <Tab eventKey={TabEventKeys.CREATED_OBJECTIVE}>
                    <div
                      className={style.tab_container_wrapper}
                      id="team-objective"
                    >
                      <div className={style.tab_heading}>
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={4} xl={6}>
                            <div className={style.tab_title}>
                              <h2>Objectives Available</h2>
                              <h3>You can select 1 objective at time</h3>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className={style.search_bar}>
                        <Row>
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={4}
                            xl={4}
                            className="d-flex"
                          >
                            <Form className={style.allobjective_searchbar}>
                              <Form.Group className="form-postfix mb-0 w-100">
                                <Form.Control
                                  // size="sm"
                                  type="text"
                                  placeholder="E.g. Java, React JS, UX"
                                  name="searchBox"
                                  className={className}
                                  onChange={search}
                                  value={searchFilter ? searchFilter : ""}
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") e.preventDefault();
                                  }}
                                />
                                <Button
                                  className="close-button"
                                  type="reset"
                                  onClick={resetSearch}
                                >
                                  <span className="close-icon"></span>
                                </Button>
                                <div className="form-postfix-icon">
                                  <img src={IC_INPUT_SEARCH} title="" alt="" />
                                </div>
                              </Form.Group>
                            </Form>
                            <div
                              className={[
                                style.filter_info_tooltip,
                                "custom-tooltip",
                              ].join(" ")}
                            >
                              <div className="box-info">
                                <ReactSVG src={IC_INFO} title="" alt="info" />
                              </div>
                              <div className="info-attach-file">
                                <span className="attachment-title">
                                  Search Criteria
                                </span>
                                <ul>
                                  <li>
                                    If you want to search objective than search
                                    through objective name.
                                  </li>
                                  <li>
                                    If you want to use filter tags use # E.g.
                                    #test
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={8}
                            xl={8}
                            className={style.dw_obj_btn}
                          >
                            <button
                              onClick={() => setOpenPopup(true)}
                              type="button"
                              className={[
                                "btn-round save_box_btn btn btn-secondary",
                                style.create_new_btn,
                                buttonClass,
                              ].join(" ")}
                            >
                              <strong>Emp OKR Pending</strong>
                            </button>
                            <div
                              className={[
                                style.filter_info_tooltip,
                                style.filter_info_tooltip_pending,
                                "custom-tooltip",
                              ].join(" ")}
                            >
                              <div className="box-info">
                                <ReactSVG src={IC_INFO} title="" alt="info" />
                              </div>
                              <div className="info-attach-file">
                                <span className="attachment-title">
                                  Complete Pending Employees OKR
                                </span>
                                <ul>
                                  <li>
                                    This will show a list of reportees whose OKRs have either not been assigned or have not reached 100% completion.
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <span
                              className={[
                                "btn btn-primary dw-obj-btn",
                                createObjectiveResponse.length === 0
                                  ? "disabled"
                                  : "",
                              ].join(" ")}
                              onClick={() => {
                                if (createObjectiveResponse.length === 0)
                                  return false;
                                exportTeamObjectives(createObjectiveResponse);
                              }}
                            >
                              Download Objectives
                            </span>
                          </Col>
                        </Row>
                      </div>
                      <div className={style.allobjective_table}>
                        <div className="box-light-gradient"></div>
                        <Col xs={12}>
                          <div className="custom-table table-theme-1 pt40">
                            {createPageOfItems &&
                              createPageOfItems.length > 0 ? (
                              <Table className={style.tab_table_responsive}>
                                <thead>
                                  <tr>
                                    <td className={style.name}>Name</td>
                                    <td className={style.key_result}>
                                      Key Results
                                    </td>
                                    <td className={style.duration}>
                                      Key Value
                                    </td>
                                    <td className={style.tags}>
                                      Tags
                                    </td>
                                    <td className={style.action}>Action</td>
                                  </tr>
                                </thead>

                                {createPageOfItems
                                  .sort(
                                    (a, b) =>
                                      moment(b.createdDate) -
                                      moment(a.createdDate)
                                  )
                                  .map((teamRow, index) => (
                                    <MyCreatedRows
                                      teamRow={teamRow}
                                      key={"TEAM_ROW" + index}
                                      resetSearch={resetSearch}
                                      defaultKey={defaultKey}
                                      TabEventKeys={TabEventKeys}
                                      getObjective={getObjective}
                                      // added changes for disable OKR feature from Engage
                                      isAllObjective={true}
                                    />
                                  ))}
                              </Table>
                            ) : (
                              <div>
                                <NoDataFound
                                  description={noData.WE_DONT_HAVE_THE_DATA}
                                />
                                <div className="gap120"></div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </div>
                    </div>
                    <Pagination
                      pageSize={size}
                      items={createdData}
                      resetData={resetData}
                      onChangeObjectivePage={onChangeObjectivePage}
                    />
                    {popupTeam && popupTeam.length > 0 && (
                      <OKRDetailsPopup
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                        teamData={popupTeam}
                      />
                    )}
                    <ConfirmationPopup
                      show={confirmPopup}
                      submit={deleteObjective}
                      hide={() => setConfirmPopup(false)}
                      headerText={headerText}
                      subText={subText}
                    />
                    <ComfirmUploadPopup
                      isTeamObjective={true}
                      show={confirmUploadPopup}
                      fileName={file && file.name}
                      setFile={setFile}
                      uploadFileAPI={uploadFileAPI}
                      errorList={errorList}
                      submit={() => {
                        setConfirmUploadPopup(false);
                        uploadObjectiveFile(objectivesData);
                      }}
                      errorFlag={errorFlag}
                      hide={() => setConfirmUploadPopup(false)}
                      isFromObjective={true}
                      columnError={isColumnError}
                    />
                    <DetailErrorInfoPopup
                      show={errorPopup}
                      submit={() => setErrorPopup(false)}
                    />
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default AllObjectives;
